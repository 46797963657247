import axios from 'axios';

const BASE_URL = 'https://api-livia.kollecta.io';

const getEndpointPath = (theme, endpoint) => {
    if (theme === 'cecilia' && endpoint === '/completion') {
        return '/cecilia';
    }
    if (theme === 'vivo' && endpoint === '/completion') {
        return '/vivo';
    }
    if (theme === 'renata' && endpoint === '/completion') {
        return '/recup';
    }
    if (theme === 'cheers' && endpoint === '/completion') {
        return '/cheers';
    }
    return endpoint;
};

export const getCompletion = async (question, cellphone, theme) => {
    const url = `${BASE_URL}${getEndpointPath(theme, '/completion')}`;
    const data = {
        question,
        cellphone
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching completion:', error);
        throw error;
    }
};

export const getMessagesByPhone = async (phone, theme) => {
    const url = `${BASE_URL}${getEndpointPath(theme, `/messages/phone/${phone}`)}`;

    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching messages by phone:', error);
        throw error;
    }
};
