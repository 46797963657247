import React, { createContext, useState, useEffect, useRef } from 'react';
import livia from '../theme/livia.scss';
import vivo from '../theme/livia.scss';
import cecilia from '../theme/cecilia.scss';
import renata from '../theme/livia.scss';
import carol from '../theme/livia.scss';

export const ThemeContext = createContext();

const getThemeFromUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === 'livia.kollecta.io' || hostname === 'livia.resolvi.digital') {
        return { theme: livia, name: 'livia' };
    } else if (hostname === 'cecilia.kollecta.io' || hostname === 'desktop.resolvi.digital' || hostname === 'localhost') {
        return { theme: cecilia, name: 'cecilia' };
    } else if (hostname === 'vivo.resolvi.digital') {
        return { theme: vivo, name: 'vivo' };
    } else if (hostname === 'recup.resolvi.digital') {
        return { theme: renata, name: 'renata' };
    } else if (hostname === 'cheers.resolvi.digital') {
        return { theme: carol, name: 'cheers' };
    } else {
        return { theme: cecilia, name: 'cecilia' };
    }
};

const getThemeFromUrlOrLocalStorage = () => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
        const { themeName } = JSON.parse(storedTheme);
        if (themeName === 'livia') return { theme: livia, name: 'livia' };
        if (themeName === 'cecilia') return { theme: cecilia, name: 'cecilia' };
        if (themeName === 'vivo') return { theme: vivo, name: 'vivo' };
        if (themeName === 'renata') return { theme: renata, name: 'renata' };
    }
    return getThemeFromUrl();
};

const ThemeContextProvider = (props) => {
    const [theme, setTheme] = useState({ theme: livia, name: 'livia' });
    const textareaRef = useRef(null); // Referência para o textarea

    const generateCssVariables = (theme) => {
        const root = document.documentElement;

        root.style.setProperty('--primary-color', theme.theme.primaryColor);
        root.style.setProperty('--secondary-color', theme.theme.secondaryColor);
        root.style.setProperty('--tertiary-color', theme.theme.tertiaryColor);

        root.style.setProperty('--primary-bg', `linear-gradient(to right, ${theme.theme.primaryColor}, ${theme.theme.secondaryColor})`);
    };

    const updateFavicon = (themeName) => {
        const favicon = document.getElementById('favicon');
        if (themeName === 'livia') {
            favicon.href = '/livia.ico';
        } else if (themeName === 'cecilia') {
            favicon.href = '/cecilia.ico';
        } else if (themeName === 'vivo') {
            favicon.href = '/livia.ico';
        } else if (themeName === 'renata') {
            favicon.href = '/livia.ico';
        }
    };

    useEffect(() => {
        const currentTheme = getThemeFromUrlOrLocalStorage();
        setTheme(currentTheme);
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify({ ...theme, themeName: theme.name }));
        generateCssVariables(theme);
        updateFavicon(theme.name);

        // Restaura o foco no textarea após a atualização do tema
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [theme]);

    useEffect(() => {
        document.title = theme.name === 'cecilia' ? 'Cecilia' : theme.name === 'renata' ? 'Renata' : 'Livia';
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme }}>
            {props.children}
            <textarea 
                ref={textareaRef} // Associando a referência ao textarea
                placeholder="Digite algo..."
            />
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
