import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import styles from './Header.module.scss';
import ceciliaLogo from './cecilia.png';
import liviaLogo from './livia.png';
import recupLogo from './recup.png';
import carolLogo from './cheers.png';

const Header = () => {
    const { theme } = useContext(ThemeContext);
    let themeName;
    let logoSrc;

    if (theme.name === 'cecilia') {
        themeName = 'Cecíl.ia';
        logoSrc = ceciliaLogo;
    } else if (theme.name === 'livia') {
        themeName = 'Lívia';
        logoSrc = liviaLogo;
    } else if (theme.name === 'recup') {
        themeName = 'Renata';
        logoSrc = recupLogo;
    } else if (theme.name === 'vivo') {
        themeName = 'Vivian';
    } else if (theme.name === 'renata') {
        themeName = 'Renata';
        logoSrc = recupLogo;
    } else if (theme.name === 'cheers') {
        themeName = 'Carol';
        logoSrc = carolLogo;
    }

    return (
        <div className={styles.header}>
            <img 
                src={logoSrc}
                alt="Logo"
                className={styles.logo}
            />
            <div className={styles.nameContainer}>
                <span className={styles.name}>{themeName}</span>
                <span className={styles.status}>online</span>
            </div>
        </div>
    );
};

export default Header;
