import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import styles from './ChatContainer.module.scss';
import { getCompletion, getMessagesByPhone } from '../../services/botApi';
import arrowIcon from './arrow.svg';
import moment from 'moment-timezone';
import { ThemeContext } from '../../contexts/ThemeContext';

const ChatContainer = () => {
    const [messages, setMessages] = useState([]);
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const chatContainerRef = useRef(null);
    const formRef = useRef(null);

    const { theme } = useContext(ThemeContext);

    const fetchUserIP = useCallback(async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error('Erro ao obter IP do usuário:', error);
            const randomNum = Math.floor(100000000000000 + Math.random() * 900000000000000);
            return randomNum.toString();
        }
    }, []);

    const loadMessageHistory = useCallback(async () => {
        const userIp = await fetchUserIP();
        if (userIp) {
            localStorage.setItem('userIp', userIp);
            try {
                const messagesData = await getMessagesByPhone(userIp, theme.name); // Passa o tema
                setMessages(messagesData.data);
            } catch (error) {
                console.error('Erro ao carregar histórico de mensagens:', error);
            }
        }
    }, [fetchUserIP, theme.name]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!question.trim()) {
            return;
        }
        setLoading(true);

        const userIp = localStorage.getItem('userIp');
        const newQuestion = {
            type: 'question',
            text: question,
            createdAt: new Date().toISOString(),
        };

        try {
            const response = await getCompletion(question, userIp, theme.name);
            const newAnswer = {
                type: 'answer',
                text: response.message,
                createdAt: new Date().toISOString(),
            };

            const storedQuestions = JSON.parse(localStorage.getItem('chatQuestions')) || [];
            storedQuestions.push(newQuestion);
            localStorage.setItem('chatQuestions', JSON.stringify(storedQuestions));

            const storedAnswers = JSON.parse(localStorage.getItem('chatAnswers')) || [];
            storedAnswers.push(newAnswer);
            localStorage.setItem('chatAnswers', JSON.stringify(storedAnswers));

            setMessages([...messages, newQuestion, newAnswer]);
        } catch (error) {
            console.error('Erro ao enviar pergunta:', error);
            const errorMessage = {
                type: 'error',
                text: `Tivemos um erro em consultar sua dívida, limpe o cache do navegador e tente novamente. Erro: ${error.message}`,
                createdAt: new Date().toISOString(),
            };
            setMessages([...messages, errorMessage]);
        } finally {
            setLoading(false);
            setQuestion('');
        }
    };

    const handleChange = (event) => {
        setQuestion(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const updatePaddingBottom = () => {
        if (chatContainerRef.current && formRef.current) {
            chatContainerRef.current.style.paddingBottom = `${formRef.current.offsetHeight}px`;
        }
    };

    useEffect(() => {
        loadMessageHistory();
    }, [loadMessageHistory]);

    useEffect(() => {
        scrollToBottom();
        updatePaddingBottom();
    }, [messages]);

    useEffect(() => {
        const handleResize = () => {
            updatePaddingBottom();
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div ref={chatContainerRef} id="chatContainer" className={styles.chatContainer}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`${styles.response} ${message.type === 'question' ? styles.userMessage : styles.assistantMessage}`}
                        style={{ alignSelf: message.type === 'question' ? 'flex-end' : 'flex-start' }}
                    >
                        <div className={styles.messageText}>
                            {message.type === 'question' ? (
                                message.text
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: message.text }} />
                            )}
                        </div>
                        <div className={styles.messageDate}>
                            {message.createdAt && (
                                moment(message.createdAt).format('HH:mm')
                            )}
                        </div>
                    </div>
                ))}
            </div>
            
            <form id="questionForm" className={styles.form} ref={formRef} onSubmit={handleSubmit}>
                <div className={styles.inputContainer}>
                    <textarea
                        id="question"
                        name="question"
                        className={styles.input}
                        placeholder="Mensagem"
                        value={question}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        disabled={loading}
                        required
                    />
                    {loading ? (
                        <div className={styles.spinner} />
                    ) : (
                        <img
                            src={arrowIcon}
                            alt="Enviar"
                            className={styles.sendIcon}
                            onClick={handleSubmit}
                        />
                    )}
                </div>
            </form>
        </div>
    );
};

export default ChatContainer;
